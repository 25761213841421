<template>
  <va-card :bordered="false" :color="color" gradient :to="to">
    <va-card-content>
      <h3 class="display-6 text-center">
        {{ title }}
      </h3>
      <h2 class="display-2 text-center text-white mb-0">
        {{ value }}
      </h2>
    </va-card-content>
  </va-card>
</template>

<script>
export default {
  name: "DashboardSummaryCard",
  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      type: [Number, String],
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
    to: {
      type: String,
      default: '',
      required: false,
    },
  },
}
</script>

<style scoped>

</style>
