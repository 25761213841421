<template>
  <div class="dashboard">
    <div class="row row-equal">
      <div class="flex md3">
        <DashboardSummaryCard to="/admin/programs" title="Programmes Actifs" color="primary" :value="nbTours"/>
      </div>
      <div class="flex md3">
        <DashboardSummaryCard title="Dates Prévues" color="info" :value="nbTrips"/>
      </div>
      <div class="flex md3">
        <DashboardSummaryCard title="Réservations" color="success" :value="nbReservations"/>
      </div>
      <div class="flex md3">
        <DashboardSummaryCard title="Places Libre" color="warning" :value="nbSpots"/>
      </div>
    </div>
    <div class="row">
      <div class="flex md10">
        <va-card>
          <va-card-content>
            <va-data-table :items="trips" :columns="columns" :hoverable="true">
              <template #cell(price)="{ value }">{{ formatPrice(value) }}</template>
              <template #cell(status)="{ value }">{{ formatStatus(value) }}</template>
              <template #cell(start_time)="{value}"> {{ formatDate(value) }}</template>
              <template #cell(end_time)="{value}"> {{ formatDate(value) }}</template>
            </va-data-table>
          </va-card-content>
        </va-card>
      </div>
      <div class="flex md2">
        <va-card to="/admin/create_program" stripe stripe-color="success">
          <va-card-content>
            <h6 class="display-6 text--center">
              CRÉER UN PROGRAMME
            </h6>
          </va-card-content>
        </va-card>
      </div>
    </div>
  </div>
</template>

<script>
import DashboardSummaryCard from "./DashboardSummaryCard";
import moment from "moment";
import "moment/locale/fr";

export default {
  name: 'dashboard',
  components: {
    DashboardSummaryCard,
  },
  data() {
    return {
      trips: [],
      nbTours: '-',
      nbReservations: '-',
      nbSpots: '-',
      nbTrips: '-',
      columns: [
        { key: 'title', label: 'Titre' },
        { key: 'start_time', label: 'Date départ' },
        { key: 'end_time', label: 'Date de retour' },
        { key: 'base_price', label: 'Prix' },
        { key: 'status', label: 'Status' }
      ]
    }
  },
  async created() {
    moment.locale('fr')
    const response = await this.axios.get('/api/admin/dashboard')
    this.trips = response.data.trips
    this.nbTours = response.data.active_tours
    this.nbTrips = response.data.expected_trips
  },
  methods: {
    formatPrice(price) {
      return parseFloat(price).toFixed(2) + ' Dhs'
    },
    formatStatus(status) {
      switch (status) {
        case 'active':
          return 'Actif'
        case 'inactive':
          return 'Inactif'
        case 'cancelled':
          return 'Annulé'
        case 'pending':
          return 'En attente'
        default:
          return status
      }
    },
    formatDate(value) {
      const date = moment(value)
      if (date.isSame(moment(), 'day')) {
        return 'Aujourd\'hui'
      } else if (date.isSame(moment().add(1, 'day'), 'day')) {
        return 'Demain'
      } else {
        return date.format('dddd, DD MMMM YYYY')
      }
    }
  }
}
</script>

<style lang="scss">
  .row-equal .flex {
    .va-card {
      height: 100%;
    }
  }

  .dashboard {
    .va-card {
      margin-bottom: 0 !important;
      &__title {
        display: flex;
        justify-content: space-between;
      }
    }
  }
</style>
